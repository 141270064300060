<template>
    <div class="userInvite">
        <div class="invite_top">
            <div class="invite_top_a">
                <div class="invite_top_a_one">
                    好友通过您的链接或者二维码购买，即推荐成功。<span @click="isRecommend = true">推荐规则>></span>
                </div>
                <div class="invite_top_a_two" @click="wrapFun">推荐给好友</div>
            </div>
            <div class="invite_top_b">
                <div class="invite_top_b_left">
                    <div>
                        可提现<span>{{ info.user ? info.user.price : "0.00" }}</span>元
                    </div>
                    <div>
                        累计收益<span>{{
                            info.user ? info.user.total_amount : "0.00"
                        }}</span>元
                    </div>
                    <div>
                        成功推荐用户<span>{{ info.user ? info.user.invited_nums : 0 }}</span>人
                    </div>
                </div>
                <div class="invite_top_b_right">
                    <div class="invite_top_b_right_cash" @click="cashingFun">提现</div>
                    <div class="invite_top_b_right_to" @click="toCashFun">提现记录>></div>
                </div>
            </div>
            <div class="invite_top_title">推荐记录</div>
        </div>
        <div class="list">
            <div class="item" v-for="(item, index) in list" :key="index">
                <div class="item_l">
                    <div>成功推荐用户-{{ item.user_id }}</div>
                    <div>收益{{ item.price }}元</div>
                </div>
                <div class="item_time">{{ item.create_time }}</div>
            </div>
        </div>
        <el-empty v-if="!list || list.length <= 0" description="暂无数据"></el-empty>
        <page v-if="total > 12" :page="page" :limit="limit" :total="total"></page>
        <div class="bg" v-show="isWrap || isCash || isCashing || isCashSuc||isRecommend"></div>
        <div class="tips_wrap" v-show="isWrap">
            <div class="tips">
                <div class="tips_left">
                    <img :src="inviteInfo.poster" alt="" />

                    <!-- <div v-if="isCanvas">
                        <vue-canvas-poster ref="invitePoster" :widthPixels="1000" :painting="painting" @success="success"
                            @fail="fail"></vue-canvas-poster>
                    </div> -->
                    <div class="tips_left_save">
                        <a href="javascript:" @click="downloadCodeImg">保存图片</a>
                    </div>
                </div>
                <div class="tips_right">
                    <div class="tips_right_title">推荐文案</div>
                    <div class="tips_right_con" ref="message">
                        {{inviteInfo.clerical}}
                    </div>
                    <div class="tips_right_button">
                        <div @click="copyTextNoInput">复制推荐文案</div>
                    </div>
                </div>
                <div class="close" @click="isWrap = false">
                    <img src="../../assets/images/dl_icon03@2x.svg" />
                </div>
            </div>
        </div>
        <div class="cash_wrap" v-show="isCash">
            <div class="cashs">
                <div class="cash_title">提现</div>
                <div class="cash_box">
                    <div class="cash_box_title">请设置密码</div>
                    <input class="cash_box_input" v-model="cash_password"
                        type="password" maxlength="8" placeholder="请输入密码" />
                    <input class="cash_box_input" v-model="recash_password"
                        type="password" maxlength="8" placeholder="请再次输入密码" />
                    <div class="cash_password" @click="passwordFun">确认密码</div>
                </div>

                <div class="close" @click="isCash = false">
                    <img src="../../assets/images/dl_icon03@2x.svg" />
                </div>
            </div>
        </div>
        <div class="cash_wrap" v-show="isCashing">
            <div class="cashs">
                <div class="cash_title">提现</div>
                <div class="cash_box">
                    <div class="cashing_box_title">提现金额</div>
                    <input class="cashing_box_input" v-model="price" type="number" placeholder="请填写提现金额" />
                    <div class="cashing_box_pay">
                        <div v-if="info.is_wx == 1" :class="ispay == 1 ? 'active' : ''" @click="ispay = 1">
                            提现到微信
                        </div>
                        <div :class="ispay == 2 ? 'active' : ''" @click="ispay = 2">
                            提现到支付宝
                        </div>
                    </div>
                    <div class="cashing_box_form" v-if="ispay == 1">
                        <input class="cashing_box_input" v-model="name" type="text" placeholder="请填写真实姓名" />
                    </div>
                    <div class="cashing_box_form" v-if="ispay == 2">
                        <input class="cashing_box_input" v-model="name" type="text" placeholder="请填写真实姓名" />
                        <input class="cashing_box_input" v-model="mobile" type="text" placeholder="请填写支付宝账号（手机号或邮箱）" />
                    </div>
                    <div class="cashing_box_title cashing_password">提现密码 <span @click="toSetPassword">(点击此处添加、修改提现密码)</span></div>
                    <input class="cashing_box_input" v-model="cash_password" type="password" placeholder="请填写提现密码" />
                    <div class="cashing_save" @click="cashingSaveFun">提现</div>
                </div>

                <div class="close" @click="isCashing = false">
                    <img src="../../assets/images/dl_icon03@2x.svg" />
                </div>
            </div>
        </div>
        <div class="cash_wrap" v-show="isCashSuc">
            <div class="cashs">
                <div class="cash_box cash_box_suc">
                    <div class="cash_success">提现成功</div>
                    <div class="cash_success_tip">恭喜您，提现成功！</div>
                </div>

                <div class="close" @click="isCashSuc = false">
                    <img src="../../assets/images/dl_icon03@2x.svg" />
                </div>
            </div>
        </div>
        <div class="cash_wrap" v-show="isRecommend">
            <div class="cashs">
                <div class="cash_recommend">
                    <div>推荐规则</div>
                    <div v-html="recommendinfo.content"></div>
                </div>

                <div class="close" @click="isRecommend = false">
                    <img src="../../assets/images/dl_icon03@2x.svg" />
                </div>
            </div>
        </div>
        <!-- <canvas id="myCanvas"></canvas> -->
    </div>
</template>

<script>
const format = (percentage) => (percentage === 100 ? "Full" : `${percentage}%`);
// import { WOW } from 'wowjs'
import page from "../../components/page.vue";
export default {
    name: "userInvite",
    components: {
        page,
    },
    data() {
        return {
            // 学习记录
            list: [],
            // 全部作业
            worklist: [],

            // 分页
            total: 0, //总数
            page: 1, //页数
            limit: 12, //每页个数

            // 收藏参数
            type: "", //类型
            collId: "", //收藏id

            is_benren: 1,

            tabindex: 1,

            isWrap: false,
            isCash: false,
            isCashing: false, //
            isCashSuc: false, //提现成功

            ispay: 1, //提现方式
            price: '',//提现金额
            name: '',//真实姓名
            mobile:'',//支付宝账号

            userId: 0,

            info: {},
            // 合成海报
            haibaoImage: "",
            painting: {},
            isCanvas: false,

            inviteInfo: {}, //推荐信息

            // 设置密码
            cash_password: "",
            recash_password: "",

            isRecommend: false,//推荐规则
            recommendinfo:{}
        };
    },
    mounted() {
        this.$parent.tab = 2;
        this.$parent.tabIndex = 9;
        this.$parent.$parent.routerIndex = 0;
        this.$parent.$parent.showIndex = 1;
        this.is_benren = this.$parent.is_benren;
        this.userId = this.$route.query.userId;
        this.listFun();
        this.qrcodeFun();
        this.recommendFun()
    },
    methods: {
        listFun(e) {
            var that = this;
            this.$api.POST(
                this.$face.invite_record_index,
                {
                    page: this.page,
                    limit: this.limit,
                },
                function (res) {
                    that.info = res.data;
                    if (e != 'password') {
                        that.list = res.data.list;
                    }
                    that.loading = false;
                    if (that.info.is_wx != 1) {
                        that.ispay = 2;
                    }
                    // console.log(that.coupon_list);
                }
            );
        },
        //获取二维码
        qrcodeFun() {
            var that = this;
            this.$api.POST(
                this.$face.invite_record_invite,
                {
                    type: 1,
                },
                function (res) {
                    that.inviteInfo = res.data;
                    // that.painting = {
                    //     width: "340px",
                    //     height: "470px",
                    //     background: "",
                    //     views: [
                    //         {
                    //             type: "image",
                    //             url: require("../../assets/images/haibao.jpg"),
                    //             css: {
                    //                 top: "0",
                    //                 left: "0",
                    //                 borderRadius: "0",
                    //                 width: "340px",
                    //                 height: "477px",
                    //             },
                    //         },
                    //         {
                    //             type: "qrcode",
                    //             content: that.inviteInfo.qrcode,
                    //             css: {
                    //                 top: "381px",
                    //                 left: "257px",
                    //                 color: "",
                    //                 width: "58px",
                    //                 height: "58px",
                    //             },
                    //         },
                    //         // ....
                    //     ],
                    // };
                    // that.$nextTick(() => {
                    //     that.isCanvas = true;
                    // });
                }
            );
        },

        recommendFun() {
            var that = this;
            this.$api.POST(this.$face.singleInfo, {
                id: 15
            }, function(res) {
                that.recommendinfo = res.data;
            })    
        },

        // 提现
        cashingFun() {
            if (this.info.user.is_lifelong_vip != 1) {
                this.$utile.prompt("error", "只有终身会员才可以提现");
                return false
            }
            if (this.info.is_cash_password == 1) {
                this.isCashing = true;
            } else {
                this.toSetPassword()
            }
        },
        toSetPassword() {
            this.$router.push({
                name: "userInfo",
                query: {
                    userId: this.userId,
                    type:'pw'
                },
            });
        },

        // 提现
        cashingSaveFun() {
            var that = this;
            var url = this.ispay == 1 ? this.$face.cash_record_apply_cash : this.$face.cash_record_apply_cashalipay;
            var data1 = {
                price: this.price,
                name: this.name,
                cash_password:this.cash_password
            }
            var data2 = {
                price: this.price,
                name: this.name,
                cash_password: this.cash_password,
                mobile:this.mobile
            }
            this.$api.POST(
                url,
                this.ispay == 1 ?data1:data2,
                function (res) {
                    that.isCashing = false;
                    that.$utile.prompt("success", res.msg);
                    that.isCashSuc = true;
                    that.listFun()
                }
            );
        },

        // 设置密码
        passwordFun() {
            if (!this.cash_password) {
                this.$utile.prompt("error", "请输入密码");
                return false
            }
            if (!this.cash_password) {
                this.$utile.prompt("error", "请再次输入密码");
                return false
            }
            if (this.cash_password!=this.cash_password) {
                this.$utile.prompt("error", "两次输入密码不一致");
                return false
            }
            var that = this;
            this.$api.POST(
                this.$face.member_addcash,
                {
                    cash_password: this.cash_password,
                    recash_password: this.recash_password,
                },
                function (res) {
                    that.listFun('password')
                    that.isCash = false;
                    that.isCashing = true;
                    that.$utile.prompt("success", "密码设置成功");
                }
            );
        },

        copyTextNoInput() {
            const storage = document.createElement("textarea");
            storage.value = this.$refs.message.innerHTML;
            document.body.appendChild(storage);
            storage.select();
            storage.setSelectionRange(0, 99999);
            document.execCommand("copy");
            document.body.removeChild(storage);
            this.$utile.prompt("success", "已复制到剪贴板");
        },
        //提现记录
        toCashFun() {
            if (this.info.user.is_lifelong_vip != 1) {
                this.$utile.prompt("error", "只有终身会员才可查看");
                return false
            }
            this.$router.push({
                name: "userWithdraw",
                query: {
                    userId: this.userId,
                },
            });
        },

        // 推荐给好友
        wrapFun() {
            if (this.info.user.is_lifelong_vip == 1) {
                this.isWrap = true
            } else {
                this.$utile.prompt("error", "只有终身会员才可以推荐");
            }
        },

        downloadCodeImg() {
            console.log("下载图片");
            // var a = document.createElement("a");
            // a.download = "邀请海报";
            // // 设置图片地址
            // a.href = this.inviteInfo.poster;
            // a.click();
            const downloadElement = document.createElement('a')
            downloadElement.href = this.inviteInfo.poster
            downloadElement.target = '_blank'
            downloadElement.setAttribute('download', '');
            downloadElement.download = "邀请海报"
            document.body.appendChild(downloadElement)
            downloadElement.click() // 点击下载
            document.body.removeChild(downloadElement) // 下载完成移除元素
        },
    },
    watch: {
        cash_password(newval) {
            newval = newval.replace(/[^\w/]/ig,'');
            this.$nextTick(() => {
                this.cash_password = newval;
            });
        },
        recash_password(newval) {
            newval = newval.replace(/[^\w/]/ig,'');
            this.$nextTick(() => {
                this.recash_password = newval;
            });
        },
    },
};
</script>

<style scoped>
.userInvite {
    margin-top: 26px;
    height: auto;
    overflow: hidden;
    padding-bottom: 26px;
}

.invite_top {
    background: #fff;
    border-radius: 10px 10px 0 0;
    padding-top: 56px;
    padding-left: 20px;
    padding-right: 54px;
}

.invite_top_a,
.invite_top_b {
    border-bottom: 1px solid #dadada;
    padding-left: 18px;
}

.invite_top_a {
    padding-bottom: 42px;
}

.invite_top_a_one {
    font-size: 18px;
    line-height: 1;
    color: #3d3b39;
    margin-bottom: 38px;
}

.invite_top_a_one span {
    color: #7777fe;
    cursor: pointer;
}

.invite_top_a_two {
    width: 217px;
    height: 62px;
    line-height: 62px;
    text-align: center;
    border-radius: 8px;
    background-color: #6261fe;
    font-size: 26px;
    color: #fff;
    cursor: pointer;
}

.invite_top_b {
    padding-top: 32px;
    padding-bottom: 48px;
    display: flex;
    align-items: end;
    justify-content: space-between;
}

.invite_top_b_left {
    display: flex;
    align-items: end;
}

.invite_top_b_left div {
    font-size: 17px;
    line-height: 1;
    margin-left: 106px;
}

.invite_top_b_left div:first-child {
    margin: 0;
}

.invite_top_b_left div span {
    font-size: 32px;
    line-height: 0.8;
    font-weight: bold;
    color: #6261fe;
    margin: 0 8px 0 18px;
}

.invite_top_b_left div:nth-child(1) span {
    font-size: 42px;
}

.invite_top_b_right {
    display: flex;
    align-items: center;
}

.invite_top_b_right_cash {
    width: 136px;
    height: 44px;
    line-height: 44px;
    text-align: center;
    border-radius: 10px;
    background-color: #6261fe;
    font-size: 21px;
    color: #fff;
    margin-right: 26px;
}

.invite_top_b_right_to {
    color: #7777fe;
    margin-right: 8px;
    cursor: pointer;
}

.invite_top_title {
    font-size: 25px;
    line-height: 1;
    padding: 26px 0 22px 18px;
}

.item {
    padding-left: 38px;
    padding-right: 80px;
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    border-radius: 10px;
    margin-bottom: 12px;
}

.item:first-child {
    border-radius: 0 0 10px 10px;
}

.item_l {
    display: flex;
    align-items: center;
}

.item_l div:first-child {
    width: 348px;
    font-size: 18px;
}

.item_l div:nth-child(2) {
    width: 348px;
    font-size: 21px;
    color: #6261fe;
    font-weight: normal;
}

.item_time {
    font-size: 12px;
    color: #999;
}

.bg {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0.6;
    z-index: 90;
}

.tips_wrap,
.cash_wrap {
    background-color: #fff;
    border-radius: 10px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 91;
    width: 710px;
    height: 596px;
}

.tips {
    position: relative;
    padding: 38px 40px 32px 50px;
    display: flex;
    justify-content: space-between;
}

.tips_left {
    width: 340px;
}

.tips_left img {
    display: block;
    width: 340px;
    height: auto;
    margin-bottom: 14px;
}

.tips_left_save,
.tips_right_button {
    display: flex;
    justify-content: flex-end;
}

.tips_left_save a {
    width: 122px;
    height: 34px;
    line-height: 34px;
    text-align: center;
    border-radius: 8px;
    background-color: #6261fe;
    color: #fff;
    font-size: 17px;
}

.tips_right {
    width: 250px;
}

.tips_right_title {
    font-size: 22px;
    line-height: 1;
    margin-bottom: 15px;
}

.tips_right_con {
    width: 100%;
    height: 267px;
    border: 1px solid #b8b8b8;
    padding: 7px;
    box-sizing: border-box;
    font-size: 15px;
    line-height: 28px;
    word-break: break-all;
}

.tips_right_button div {
    width: 146px;
    height: 34px;
    line-height: 34px;
    text-align: center;
    border-radius: 8px;
    background-color: #6261fe;
    color: #fff;
    font-size: 17px;
    margin-top: 16px;
    cursor: pointer;
}

.close {
    position: absolute;
    top: 0px;
    right: -40px;
    width: 32px;
    height: 32px;
    cursor: pointer;
}

.close img {
    width: 32px;
    height: 32px;
}

.cash_wrap {
    width: 410px;
    height: auto;
}

.cashs {
    position: relative;
}

.cash_title {
    width: 100%;
    height: 66px;
    border-bottom: 2px solid #ececec;
    box-sizing: border-box;
    font-size: 20px;
    color: #333;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cash_box {
    padding: 0 38px 54px;
}

.cash_box_title {
    margin-top: 40px;
    font-size: 18px;
    line-height: 1;
}

.cash_box_input,
.cashing_box_input {
    width: 100%;
    height: 35px;
    padding: 0 18px;
    border: 1px solid #e5e5e5;
    border-radius: 6px;
    box-sizing: border-box;
    margin-top: 22px;
}

.cash_box_input::-ms-input-placeholder {
    color: #a1a1a1;
}

.cash_box_input::-webkit-input-placeholder {
    color: #a1a1a1;
}

.cash_password {
    margin-top: 160px;
    width: 100%;
    height: 40px;
    line-height: 40px;
    text-align: center;
    border-radius: 6px;
    background-color: #5859ff;
    font-size: 20px;
    color: #fff;
    cursor: pointer;
}

.cash_box_suc {
    padding-top: 73px;
}

.cash_success {
    width: 100%;
    height: 40px;
    line-height: 40px;
    text-align: center;
    border-radius: 6px;
    background-color: #5859ff;
    font-size: 20px;
    color: #fff;
    margin-bottom: 16px;
}

.cash_success_tip {
    font-size: 13px;
    color: #999;
    line-height: 1;
    text-align: center;
}

.cashing_box_title {
    font-size: 18px;
    line-height: 1;
    margin-top: 33px;
}

.cashing_box_title::before {
    content: "*";
    color: #e93829;
}

.cashing_box_pay {
    display: flex;
    align-items: center;
    margin-top: 30px;
}

.cashing_box_pay div {
    font-size: 18px;
    color: #999;
    margin-right: 48px;
    cursor: pointer;
}

.cashing_box_pay div.active {
    color: #333;
}

.cashing_box_pay div::before {
    content: "*";
}

.cashing_box_pay div.active::before {
    content: "*";
    color: #e93829;
}

.cashing_box_form {
    height: 114px;
    /* margin-bottom: 68px; */
}

.cashing_password{
    margin-top: 30px;
}
.cashing_password span{
    font-size: 12px;
    color: #5859ff;
    margin-left: 10px;
}

.cashing_save {
    margin-top: 30px;
    width: 100%;
    height: 40px;
    line-height: 40px;
    text-align: center;
    border-radius: 6px;
    background-color: #5859ff;
    font-size: 20px;
    color: #fff;
}



.cash_recommend{
    padding: 0 30px 30px;
}
.cash_recommend div{
    font-size: 22px;
    text-align: center;
    line-height: 50px;
}
.cash_recommend div:nth-child(2){
    font-size: 16px;
    line-height: 28px;
    color: #666;
    text-align: left;
}
</style>
